/**
 */
var modules = modules || {};
modules.coverflow = modules.coverflow || {};
modules.coverflow.Loading = React.createClass({
    render: function () {
        return (
            <div className='container'>
                <div className='content' />
            </div>
        );
    }
});
modules.coverflow.CoverReservationCell = React.createClass({
    render: function () {
        return (
            <td className={this.props.className}>
                <div>{this.props.value == 0 ? '' : this.props.value }</div>
            </td>
        );
    }
});

modules.coverflow.CoverReservationTopRow =  React.createClass({
    render: function() {
        var emptyCells = _.map(_.range(this.props.numColumns), function (x, index) {
            return (<modules.coverflow.CoverReservationCell key={index} className='odd' value='0'/>)
        });
        return (
            <tr className='reservation-row top'>
                {emptyCells}
            </tr>
        );
    }
});

modules.coverflow.CoverReservationHeader = React.createClass({
    render: function () {
        var selected_shift_value = modules.coverflow.helpers.selected_shift ?
            modules.coverflow.helpers.selected_shift['persistent_id'] : '',
            selected_area = modules.coverflow.helpers.selected_area;
        var shiftOptions = _.map(this.props.shifts, function(shift, index) {
            return (
                <div key={index}
                    style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
                    className={selected_shift_value == shift.persistent_id ? 'selected option' : 'option'}
                    data-id={shift.persistent_id}>
                        {shift.name}
                </div>
            );
        }, this);

        var seating_areas = _.map(this.props.seating_areas, function(area_name, area_id) {
            return (
                <option key={area_id}
                    value={area_id}
                >
                    {area_name}
                </option>
            );
        }, this);
        var calendarimageUrl= modules.coverflow.helpers.media_url+"images/icons/calendar-negative.png",
            printImageUrl= modules.coverflow.helpers.media_url+"images/dashboard_print.png",
            _seating_areas = modules.coverflow.helpers._seating_areas;

        var header_div = 
            modules.coverflow.helpers.mode.is_headless_mode ?
            (<div className="header-wrapper">
                <div style={{padding: 5, float: 'left'}}>
                    {modules.coverflow.helpers.selected_date}&nbsp;|&nbsp;
                    {modules.coverflow.helpers.selected_shift ?
                        modules.coverflow.helpers.selected_shift['name'] : 'All'}
                </div>
                <div className="print-icon" style={{padding: 5, margin: 0}} onClick={this.onClickPrint}>
                    <img src={printImageUrl}/>
                </div>
                <br className="clear" />
            </div>)
            : modules.coverflow.helpers.mode.is_print_mode ?
            (<div className="header-wrapper">
                For date:&nbsp;{modules.coverflow.helpers.selected_date}
                <br/>
                area:&nbsp;
                {_seating_areas[modules.coverflow.helpers.selected_area] || 'All'}
                <br/>
                shift:&nbsp;
                {modules.coverflow.helpers.selected_shift ?
                    modules.coverflow.helpers.selected_shift['name'] : 'All'}
            </div>)
            :
            (<form className="styled">
                <div className="header-wrapper">
                    <div className="form-element cal calendar text">
                        <label htmlFor="secondary-calendar">
                            <p className="input">
                                <input readOnly="true"  type="text"
                                    name="date" className="date-selector hasDatePicker"/>
                                <img src={calendarimageUrl} />
                            </p>
                        </label>
                        <p className="validator" sr-validate-error="">Please select a date</p>
                    </div>
                    <div className="shift-selector">
                        {shiftOptions}
                    </div>
                    <div className="selected_area form-element select">
                        <label htmlFor="selected_area">
                            <p className="input">
                                <select name="selected_area" value={selected_area}>
                                    <option value=''>All reservations</option>
                                    {seating_areas}
                                </select>
                                <span className="downer"></span>
                            </p>
                        </label>
                    </div>
                    <div className="print-icon" onClick={this.onClickPrint}>
                        <img src={printImageUrl}/>
                    </div>
                    <br className="clear" />
                </div>
            </form>);

        return (header_div);
    },

    onClickPrint: function() {
        var url = modules.coverflow.helpers.base_url +
                "/reservations/day/coverflow/" +
                modules.coverflow.helpers.selected_date +
            "?shift_persistent_id=" + (modules.coverflow.helpers.selected_shift || {})['persistent_id'] +
            "&selected_area=" + modules.coverflow.helpers.selected_area +
            "&print=true";
        window.open(url);
    }
});

modules.coverflow.CoverReservationColumn = React.createClass({
    render: function () {
        return (
            <col className={this.props.className}></col>
        );
    }
});

modules.coverflow.CoverReservationRow = React.createClass({
    render: function () {
        var coverCells = this.props.guests.map(function (guestpair, index) {
            var guestnum = guestpair[0];
            var guesttype = guestpair[1];
            var className = index % 2 == 0 ? "even" : "odd";
            className = className + (guesttype ? ' confirmed' : ' not-confirmed')
            className = className + (guestnum > 0 ? ' not-empty' : ' empty')
            return (
                <modules.coverflow.CoverReservationCell className={className} value={guestnum} key={index}/>
            );
        });

        return (
            <tr className="reservation-row">
                <modules.coverflow.CoverReservationCell className='odd' value='0'/>
                {coverCells}
            </tr>
        );
    }
});

modules.coverflow.CoverReservationTableFooter = React.createClass({
    render: function () {
        return !this.props.is_future_date ? (
            <div className={this.props.className}>
                <span className="label">{'total booked:'}</span>
                <span className="big">{this.props.total_covers}</span>{' covers'}
                <span className="big">{this.props.total_upcoming}</span>{' upcoming'}
                <span className="big">{this.props.total_seated}</span>{' seated'}
                <span className="big">{this.props.total_completed}</span>{' completed'}
            </div>
        ) : (
            <div className={this.props.className}>
                <span className="label">{'total booked:'}</span>
                <span className="big">{this.props.total_covers}</span>{' covers'}
                <span className="big">{this.props.total_reservations}</span>{' reservations'}
                <span className="big">{this.props.total_confirmed}</span>{' confirmed'}
                <span className="big">{this.props.total_booked}</span>{' booked'}
            </div>
        );
    }
});

modules.coverflow.CoverReservationTable = React.createClass({
    render: function () {
        var is_print_mode = modules.coverflow.helpers.mode.is_print_mode,
            is_standalone_mode = modules.coverflow.helpers.mode.is_standalone_mode;

        var generateArray = function (element, length) {
            return Array(length + 1).join(element).split('');
        };

        var coverRows = this.props.guestlists.map(function (guestlist, index) {
            return (
                <modules.coverflow.CoverReservationRow guests={guestlist} key={index}/>
            );
        });

        var coverTotalsRow = this.props.guesttotals.map(function (total, index) {
            return (
                <modules.coverflow.CoverReservationCell
                    className='totals'
                    value={total >= 0 ? (total > 0 ? total : '-') : ''}
                    key={index}/>
            )
        })

        var reservationsTotalsRow = this.props.reservation_counts.map(function (total, index) {
            return (
                <modules.coverflow.CoverReservationCell
                    className='totals'
                    value={total >= 0 ? (total > 0 ? total : '-') : ''}
                    key={index}/>
            )
        })

        var numColumns = this.props.intervals.length;
        var today = new Date();
        var is_future_date = new Date(this.props.selected_date) > today;
        var coverColumns = generateArray(1, numColumns).map(function (v, index) {
            var className = index % 2 == 0 ? "even" : "odd";
            return (
                <modules.coverflow.CoverReservationColumn className={className} key={index} />
            );
        });

        var coverIntervals = this.props.intervals.map(function (interval, index) {
            var className = index % 2 == 0 ? "even" : "odd";
            interval = Pmp.Utils.timeWithLocale(interval);

            // Drop suffix AM/PM if not an exact hour (i.e. 5:00 PM, 6:00 PM)
            if (!Pmp.Manager.Global._is_military_time) {
              if (interval.indexOf("00") == -1) {
                  interval = interval.substring(0, interval.length - 3);
              } else {
                  className = className + " hour"
              }
            }
            
            return (
                <modules.coverflow.CoverReservationCell className={className} key={index} value={interval}/>
            );
        });

        var onClickClose = function (event) {
            $('#coverflow-content-wrapper').trigger('click');
        };

        var closeLink = (!(modules.coverflow.helpers.mode.is_print_mode  ||
                modules.coverflow.helpers.mode.is_standalone_mode)) ?
                (<a href="#" className="close-link" onClick={onClickClose}>X</a>) : ('')

        return (
            <div className={is_print_mode ? 'print container' :
                (is_standalone_mode ? 'standalone container' : 'container')}
            >
                <div className='content'>
                    <div className='header'>
                        <modules.coverflow.CoverReservationHeader
                            className='odd close'
                            selected_shift_id={this.props.selected_shift_id}
                            shifts={this.props.shifts}
                            seating_areas={this.props.seating_areas}
                            numColumns={numColumns + 1}
                        />
                        {closeLink}
                    </div>
                    <div className='table'>
                        <table className="reservation-table">
                            <colgroup>
                                <modules.coverflow.CoverReservationColumn className='odd'/>
                                {coverColumns}
                            </colgroup>
                            <tbody>
                                <modules.coverflow.CoverReservationTopRow
                                    className='odd close'
                                    numColumns={numColumns + 1}
                                />

                                <tr className="reservation-row intervals">
                                    <modules.coverflow.CoverReservationCell className='odd' value='0'/>
                                    {coverIntervals}
                                </tr>
                                <tr className="reservation-row covers-total">
                                    <modules.coverflow.CoverReservationCell className='odd label' value='covers'/>
                                    {coverTotalsRow}
                                </tr>
                                {coverRows}
                                <tr className="reservation-row reservation-total">
                                    <modules.coverflow.CoverReservationCell className='odd label' value='reservations'/>
                                    {reservationsTotalsRow}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='footer'>
                        <modules.coverflow.CoverReservationTableFooter
                            className='odd' numColumns={numColumns + 1}
                            is_future_date={is_future_date}
                            total_reservations={this.props.total_reservations}
                            total_covers={this.props.total_counts}
                            total_confirmed={this.props.total_confirmed}
                            total_booked={this.props.total_booked}
                            total_completed={this.props.total_completed}
                            total_upcoming={this.props.total_upcoming}
                            total_seated={this.props.total_seated}
                        />
                    </div>
                </div>
            </div>
        );
    }
});


modules.coverflow.helpers = {

    padZeros: function (str, max) {
        str = str.toString();
        return str.length < max ? this.padZeros("0" + str, max) : str;
    },

    getDisplayTime: function (minutes) {
        var display_time = "AM"
        if (Math.floor(minutes / (12 * 60)) > 0) {
            display_time = "PM"
        }

        var minutes = minutes % (12 * 60),
            hours = Math.floor(minutes / 60),
            remaining_minutes = minutes % 60;

        return (hours == 0 ? "12" : hours) + ":" + this.padZeros(remaining_minutes, 2) + " " + display_time
    },

    getDayOfWeek: function(date_text) {
        var d = new Date(Date.parse(date_text));
        return d.getDay() == 0 ? 6 : d.getDay() - 1;
    },

    generateIntervals: function (shifts, selected_shift_id) {
        var selected_shift = _.first(_.filter(shifts, function (s) {
                return s.persistent_id == selected_shift_id
            }));
        if (selected_shift) {
            var adjusted_intervals = _.map(_.range(0, 96), function(i) { return timeutils.sort_order_to_time(i) }),
                start_index = _.indexOf(adjusted_intervals, Pmp.Utils.militaryTimeToAMPM(selected_shift.start_time)),
                end_index = _.indexOf(adjusted_intervals, Pmp.Utils.militaryTimeToAMPM(selected_shift.end_time));

            return adjusted_intervals.slice(start_index, end_index + 1);
        } else {
            return [];
        }
    },

    generateAllIntervals: function(shifts) {
        var all_intervals = [];
        for (i in shifts) {
            var shift = shifts[i]
            all_intervals = all_intervals.concat(this.generateIntervals(shifts, shift.persistent_id));
            if (i < shifts.length - 1) {
                all_intervals = all_intervals.concat(['']);
            }
        }
        return all_intervals;
    },

    getMatrix: function (grouped_reservations) {
        var matrix = [];
        var numRows = _.max(_.map(grouped_reservations, function (gr) {
            return gr.length;
        }))

        for (var i = 0; i < numRows + 1; i++) {
            var matrixRow = [];
            for (key in grouped_reservations) {
                var currentRow = grouped_reservations[key][i] || {};
                matrixRow.push([currentRow.total_guests || 0, currentRow.is_confirmed || false]);
            }
            matrix.push(matrixRow)
        }
        return matrix;
    },

    getTotals: function (grouped_reservations) {
        return _.map(grouped_reservations, function (gr, index) {
            return index == "" ? -1 : _.reduce(_.map(gr, function (r) {
                return r.total_guests;
            }), function (memo, num) {
                return memo + num;
            }, 0);
        })
    },

    sumList: function(list) {
        return _.reduce(list, function (a, b) {
            return a + b;
        }, 0);
    },

    processData: function (reservations, shifts) {
        var sorted_reservations = _.sortBy(reservations, function (r) {
            return -r.total_guests;
        })
        var grouped_reservations = _.groupBy(sorted_reservations, 'arrival_time_display'),
            available_intervals = this.selected_shift ?
                this.generateIntervals(shifts, this.selected_shift['persistent_id']) :
                this.generateAllIntervals(shifts);

        var emptyIntervals = _.reduceRight(available_intervals, function (g, interval) {
            g[interval] = [];
            return g;
        }, {});

        grouped_reservations = _.pick(_.extend(emptyIntervals, grouped_reservations), available_intervals);

        var reservation_counts = _.map(grouped_reservations, function (row, index) {
            return index == "" ? -1 : row.length;
        });

        var cover_counts = _.map(grouped_reservations, function (row) {
            return _.reduce(row, function (a, b) {
                return a + (_.isNumber(b.total_guests) ? b.total_guests : 0);
            }, 0);
        });

        var confirmed_counts = _.map(grouped_reservations, function (row) {
            return _.reduce(row, function (a, b) {
                return a + (b.is_confirmed ? 1 : 0);
            }, 0);
        });

        var total_completed = 0,
            total_seated = 0,
            total_upcoming = 0;

        for (var r in sorted_reservations) {
            var reservation  = sorted_reservations[r]
            if (reservation.is_complete || reservation.status == "BUS_TABLE") {
                total_completed += reservation.total_guests;
            }
            else if (reservation.is_in_service) {
                total_seated += reservation.total_guests;
            } else {
                total_upcoming += reservation.total_guests;
            }

        }

        var matrix_data = this.getMatrix(grouped_reservations),
            guest_totals = this.getTotals(grouped_reservations),
            total_reservations = this.sumList(reservation_counts),
            total_counts = this.sumList(cover_counts),
            total_confirmed = this.sumList(confirmed_counts);

        var total_booked = total_reservations - total_confirmed;

        return {
            matrix: matrix_data,
            intervals: available_intervals,
            totals: guest_totals,
            reservation_counts: reservation_counts,
            cover_counts: cover_counts,
            total_reservations: total_reservations,
            total_counts: total_counts,
            total_confirmed: total_confirmed,
            total_booked: total_booked,
            total_upcoming: total_upcoming,
            total_seated: total_seated,
            total_completed: total_completed
        };
    },

    setupDateLookup: function() {
      var jsDate = $.datepicker.parseDate('mm-dd-yy', modules.coverflow.helpers.selected_date);
      var locale = Pmp.Manager.Global._locale;
      var sel = '#coverflow-content-wrapper .date-selector';
      var dateFmt = Pmp.Utils.dateFormat(Pmp.Manager.Global._locale);
      var formattedDate = $.datepicker.formatDate(dateFmt, jsDate)
      Pmp.Utils.LocalizeDatePicker(locale, sel, sel+'-submit');
      $(sel).datepicker('option', 'defaultDate', formattedDate);
      $(sel).datepicker('option', 'minDate', $.datepicker.parseDate('mm-dd-yy', ''));
      $(sel).datepicker('option', 'showOtherMonths', true);
      $(sel).datepicker('option', 'selectOtherMonths', true);
      $(sel).datepicker('option', 'closeText', 'cancel');
      $(sel).datepicker('option', 'parentObj', this);
      $(sel).datepicker('option', 'showButtonPanel', true);
      $(sel).datepicker('option', 'onSelect',function(dateText, selObj) {
        $('#req-date-display').html(dateText);
        selObj.settings.parentObj.selected_date = selObj.settings.prependZero(selObj.currentMonth + 1)
            + "-" + selObj.settings.prependZero(selObj.currentDay) + "-" + selObj.currentYear;
        selObj.settings.parentObj.loadShifts(true);
      });
      $(sel).datepicker('option', 'beforeShow', function() {
        $('#ui-datepicker-div').addClass('calendar customize');
      });
      $(sel).datepicker('option', 'beforeShowDay', function(date) {
        if (!modules.coverflow.helpers.mode.is_standalone_mode) {
            return Pmp.Manager.Global._beforeShowDay(date);
        } else {
            return [true, "passed-date"];
        }
      });
      $(sel).datepicker('option', 'prependZero', function(num) {
        return ("0" + num).slice(-2);
      });
      $(sel).datepicker('setDate', formattedDate);
      Pmp.Utils.formatDatePicker(sel);
    },

    updateReservations: function (is_forced) {
        var self = this;
        if (!this._actuals || is_forced) {
            $.ajax({
                url: self.base_url + '/home/day/allactuals/' + self.selected_date,
                data: {'shift_persistent_id': self.selected_shift ? self.selected_shift['persistent_id'] : ''},
                success: function (response) {
                    self._actuals = self.mapActualsMilitaryTime(response.payload.content.actuals);
                    self._seating_areas = _.object(_.map(_.filter(self._actuals, function(y) {
                        return y["venue_seating_area_id"];
                    }), function(x) {
                        return [x["venue_seating_area_id"], x["venue_seating_area_name"]];
                    }));
                    self.refreshView();
                }
            });
        } else {
            self.refreshView();
        }
    },

    loadShifts: function (is_forced) {
        var self = this;
        if (!this._shifts || is_forced) {
            $.ajax({  // This should probably use '/api-yoa/shifts/get' instead so we can kill this endpoint
                url: self.base_url + '/shifts/' + self.selected_date,
                success: function (response) {
                    self._shifts = response;
                    self.resetShiftIfNeeded();
                    self.updateReservations(true);
                }
            });
        } else {
            self.updateReservations(true);
        }
    },

    bindRoomChangeHandler: function() {
        var self = this;
        $('#coverflow-content-wrapper .selected_area select').unbind('change').bind('change', function(e) {
            self.selected_area = $('#coverflow-content-wrapper .selected_area select').val();
            self.updateReservations(true);
            e.stopPropagation();
        });
    },

    resetShiftIfNeeded: function() {
        this.selected_shift = _.has(this.selected_shift, 'category') ?
            _.find(this._shifts, {'category': this.selected_shift['category']}) :
            '';
    },

    bindShiftChangeHandler: function() {
        var self = this;
        $('#coverflow-content-wrapper .shift-selector .option').unbind('click').bind('click', function(e) {
            var selected_shift_id = $(e.target).attr('data-id');
            self.selected_shift = _.find(self._shifts, {'persistent_id': selected_shift_id});
            self.selected_area = '';
            self.updateReservations(true);
            e.stopPropagation();
        });
    },

    setupEvents: function() {
        $('#coverflow-content-wrapper').show()
        this.setupDateLookup();
        this.bindShiftChangeHandler();
        this.bindRoomChangeHandler();
        // To handle click events around coverflow mask
        $('#coverflow-content-wrapper').on('click', function(event) {
            if ($('#coverflow-content-wrapper').is(":visible") &&
            !$(event.target).closest('.content').length) {
                $('#coverflow-content-wrapper').hide()
                event.stopPropagation();
                modules.coverflow.helpers._on_dismiss && modules.coverflow.helpers._on_dismiss();
            }
        });

    },

    refreshView: function () {
        if (!this._shifts) {
            this.loadShifts();
            React.render(
                    <modules.coverflow.Loading />,
                    document.getElementById('coverflow-content-wrapper')
                );
        } else if (!this._actuals) {
            this.updateReservations();
            React.render(
                    <modules.coverflow.Loading />,
                    document.getElementById('coverflow-content-wrapper')
                );
        } else {
            var selected_area = modules.coverflow.helpers.selected_area,
                filtered_actuals = _.filter(this._actuals, function(a) { return !a.is_dead
                    && (!selected_area || a.venue_seating_area_id == selected_area)}),
                processed_data = this.processData(filtered_actuals, this._shifts);
            React.render(
                <modules.coverflow.CoverReservationTable
                    guesttotals={processed_data.totals}
                    guestlists={processed_data.matrix}
                    reservation_counts={processed_data.reservation_counts}
                    cover_counts={processed_data.cover_counts}
                    total_reservations={processed_data.total_reservations}
                    total_counts={processed_data.total_counts}
                    total_booked={processed_data.total_booked}
                    total_confirmed={processed_data.total_confirmed}
                    total_completed={processed_data.total_completed}
                    total_upcoming={processed_data.total_upcoming}
                    total_seated={processed_data.total_seated}
                    shifts={this._shifts}
                    selected_shift_id={this.selected_shift_id}
                    selected_date={this.selected_date}
                    seating_areas={this._seating_areas}
                    intervals={processed_data.intervals}/>,
                document.getElementById('coverflow-content-wrapper')
            );

            if (!this.mode.is_print_mode) {
                this.setupEvents();
                if (this.mode.is_standalone_mode) {
                    $('html').css('background-color', '#FFF');
                    $('body').css('height', 'initial');
                    $('html').css('height', 'initial');
                    $('html').css('zoom', '1.5');
                    $('#nightloop').css('height', 'initial');
                }

            } else if (this.mode.is_print_mode) {
                // Setup css for printing (scaling, rotation)
                $('html').css('background-color', '#FFF');
                $('body').css('height', 'inherit');
                $('#nightloop').css('height', 'inherit');
                window.print();
            }
        }
    },

    getCurrentDate: function () {
        var today = new Date(),
            dd = today.getDate(),
            mm = today.getMonth() + 1,
            yyyy = today.getFullYear(),
            prependZero = function (num) {
                return ("0" + num).slice(-2);
            };
        return prependZero(mm) + "-" + prependZero(dd) + "-" + yyyy;
    },

    getCurrentSelectedShift: function(shifts, current_selected_id) {
        // TODO: Any better alternative?
        var selected_shift_id = current_selected_id ? current_selected_id : $('#shift-select').val();
        return shifts ? _.findWhere(shifts, {'persistent_id': selected_shift_id}) : undefined;
    },

    mapActualsMilitaryTime: function(actuals) {
        if (!actuals || !Pmp.Manager.Global._is_military_time) {
            return actuals;
        }
        return actuals.map(function(x) {
            return $.extend({}, x, {'arrival_time_display': Pmp.Utils.militaryTimeToAMPM(x['arrival_time_display'])})
        });
    },

    displayCoverFlow: function (base_url, shifts, selected_date, media_url, page,
                                selected_shift_id, selected_area, mode, actuals, on_dismiss) {
        this.base_url = base_url;
        this.media_url = media_url;
        this.selected_shift = this.getCurrentSelectedShift(shifts, selected_shift_id);
        this.mode = mode || {};
        this._actuals = this.mapActualsMilitaryTime(actuals);
        this._shifts = shifts;
        this._seating_areas = {};
        this.selected_area = selected_area || '';
        this.selected_date = selected_date || this.getCurrentDate();
        this._on_dismiss = on_dismiss

        this.refreshView();
        metric.track(page + '.showCoverFlow', {'page': page.toLowerCase()});
    }
};
